const cities = [
  {
    city: 'London',
    places:
      'Forum Kentish Town, Gunnersbury Park, The Vaults, Madame Tussauds, The Shard, Westfield',
    url: '/cities/london',
    id: 1,
    img:
      'https://images.pexels.com/photos/50632/pexels-photo-50632.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  },
  {
    city: 'Birmingham',
    places:
      'Symphony Hall, Hare & Hounds, Hippodrome Theatre, The Elgar Concert Hall, The Old Rep Theatre',
    url: '/cities/birmingham',
    id: 2,
    img:
      'https://www.law.ac.uk/globalassets/04.-locations/3.-campus-page/01.-multi-image-header/01.-birmingham/caplate_20191022_birmingham-city_head-img.jpg?width=660&height=880&mode=crop',
  },
  {
    city: 'Manchester',
    places: 'Frog & Bucket, Manchester Academy, Halle St Peters, Night People, AO Arena',
    url: '/cities/manchester',
    id: 3,
    img:
      'https://images.pexels.com/photos/5212814/pexels-photo-5212814.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  },
  {
    city: 'Glasgow',
    places:
      'Glasgow Garage, Pavilion Theatre, The SSE Hydro, Barrowland, The Poetry Club',
    url: '/cities/glasgow',
    id: 4,
    img:
      'https://images.pexels.com/photos/3035106/pexels-photo-3035106.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  },
  {
    city: 'Newcastle',
    places: 'Northumbria Institute, Slieve Donard Hotel, obalt Studios, Digital',
    url: '/cities/newcastle',
    id: 5,
    img:
      'https://images.pexels.com/photos/365717/pexels-photo-365717.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  },
  {
    city: 'Sheffield',
    places:
      'FlyDSA Arena, Leadmill, Corporation, Hall Ballroom',
    url: '/cities/sheffield',
    id: 6,
    img:
      'https://www.telegraph.co.uk/content/dam/Travel/leadAssets/34/10/sheffield_3410621a.jpg?impolicy=logo-overlay',
  },
];

export default cities;
