import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

const Container = styled.div`
  .cookiebar-custom {
    background: linear-gradient(90deg, #0d0325 0%, #0a041a 100%) !important;
    max-width: calc(100% - 310px);
    font-size: 20px;
    align-items: center !important;
    min-height: 80px;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }

  .acceptCookie {
    border-radius: 32px !important;
    color: #fff !important;
    font-size: 20px;
    background: #cc770e !important;
    font-family: 'Bebas Neue', Impact, cursive !important;
    padding: 6px 22px !important;
  }
`;

function Cookiebar() {
  return (
    <Container>
      <CookieConsent
        buttonClasses="acceptCookie"
        location="bottom"
        buttonText="OK"
        cookieName="websiteCookie"
        containerClasses="cookiebar-custom"
        expires={150}
      >
        This website uses cookies to enhance the user experience.{' '}
        <Link
          style={{ fontWeight: '300', textDecoration: 'underline' }}
          to="/cookies"
        >
          Read more
        </Link>
      </CookieConsent>
    </Container>
  );
}

export default Cookiebar;
