import React from 'react';
import { Link } from 'gatsby';
import cities from '../CityCards/cities';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h3 className="footer-column__title">Explore</h3>
        <div className="footer-column__links">
          {cities.map((city) => (
            <Link
              key={city.id}
              className="footer-column__links--link"
              to={city.url}
            >
              {city.city}
            </Link>
          ))}
        </div>
      </div>
      <div className="footer-column">
        <h3 className="footer-column__title">Zooticket</h3>
        <div className="footer-column__links">
          <Link className="footer-column__links--link" to="/">
            Homepage
          </Link>
          <Link className="footer-column__links--link" to="/about">
            About Zooticket
          </Link>
          <Link className="footer-column__links--link" to="/cookies">
            Cookies & policy
          </Link>
          <Link className="footer-column__links--link" to="/sitemap.xml">
            Sitemap
          </Link>
        </div>
      </div>
      <div className="footer-column">
        <h3 className="footer-column__title">Contact</h3>
        <div className="footer-column__links">
          <Link className="footer-column__links--link" to="/contact">
            Contact us
          </Link>
          <a className="footer-column__links--link" href="https://zooticket.dk">
            Zooticket DK
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
